<section>
  <ng-container *ngFor="let item of toasts; let i = index">
    <div class="toast alert-{{ item.type }}" [@animation]="'new'">
      <button class="alert-close" (click)="remove(i)" type="button">
        &#10799;
      </button>
      <div class="alert-icon">
        <div [ngSwitch]="item.type">
          <span *ngSwitchCase="TypeAlert.success">&#10003;</span>
          <span *ngSwitchCase="TypeAlert.error">&#9888;</span>
        </div>
      </div>
      <p class="alert-text" [innerHTML]="item.message"></p>
    </div>
  </ng-container>
</section>
