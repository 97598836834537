import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponseServer, ServerResponse } from '@models';
import { ModalSuccessService, ToastService } from '@modules';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RequestHandlerService implements HttpInterceptor {
  private statusError404: boolean;
  private modal: boolean;

  constructor(
    private readonly toast: ToastService,
    private readonly modalSuccess: ModalSuccessService,
    private readonly route: Router
  ) {
    this.modalSuccess.modal.subscribe((res: boolean) => {
      this.modal = res;
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      withCredentials: true,
    });
    return next.handle(request).pipe(
      map((event) => {
        const response = event as HttpResponseServer;
        if (response.status === 200 && this.modal) {
          const message = response.body.successMessage;
          if (message) {
            this.modalSuccess.open(message);
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.statusError404 = false;
        if (error.status === 404) {
          this.statusError404 = true;
        } else if (error.status === 401) {
          this.toast.error(error.error.errorMessage);
          this.route.navigateByUrl('/iniciar-sesion');
        } else {
          this.showErrorToast(error);
        }
        return throwError(error);
      })
    );
  }

  private showErrorToast({ error }: HttpErrorResponse): void {
    const response = error as ServerResponse;

    if (!response || !response.errorMessage) {
      this.toast.error('Error inesperado. Intente nuevamente');
      return;
    }

    if (this.statusError404) {
      return;
    }

    const { errorMessage } = response;
    this.toast.error(errorMessage);
  }
}
