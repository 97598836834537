import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from './loader.service';

@Component({
  selector: 'ggi-journey-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public notShow = false;
  public loaderSubscription = Subscription.EMPTY;
  public notShowSubscription = Subscription.EMPTY;

  constructor(
    private readonly loaderService: LoaderService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.checkIfCanShowLoader();
    this.loaderSubscription = this.loaderService.loadingStatus.subscribe(
      (value: boolean) => {
        if (!this.notShow) {
          this.isLoading = value;
          this.cdr.detectChanges();
        }
      }
    );
  }

  public checkIfCanShowLoader(): void {
    this.notShowSubscription = this.loaderService.notShow.subscribe(
      (res: boolean) => {
        this.notShow = res;
      }
    );
  }

  public ngOnDestroy(): void {
    this.loaderSubscription.unsubscribe();
    this.notShowSubscription.unsubscribe();
  }
}
