import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FirebaseConfig } from '@config/firebase.config';
import { RequestHandlerService } from '@interceptors';
import {
  LoaderModule,
  ModalAreYouSureHandlerModule,
  ModalSuccessModule,
  ToastModule,
} from '@modules';
import { QuillModule } from 'ngx-quill';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { environment } from '@environments/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ModalAreYouSureHandlerModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastModule,
    LoaderModule,
    ModalSuccessModule,
    QuillModule.forRoot(),
    AngularFireModule.initializeApp(FirebaseConfig),
    NgxGoogleAnalyticsModule.forRoot(environment.GOOGLE_ANALYTICS),
    NgxGoogleAnalyticsRouterModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestHandlerService,
      multi: true,
    },
    // { provide: ErrorHandler, useClass: SentryErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
