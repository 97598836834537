import {
  animate,
  sequence,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const Animation = {
  newToast: trigger('animation', [
    transition('* => void', [
      style({
        height: '*',
        opacity: '1',
        transform: 'translateX(0)',
      }),
      sequence([
        animate(
          '.25s ease',
          style({
            height: '*',
            opacity: '.2',
            transform: 'translateX(20px)',
          })
        ),
        animate(
          '.1s ease',
          style({
            height: '0',
            opacity: 0,
            transform: 'translateX(20px)',
          })
        ),
      ]),
    ]),
    transition('void => new', [
      style({
        height: '0',
        opacity: '0',
        transform: 'translateX(20px)',
      }),
      sequence([
        animate(
          '.1s ease',
          style({
            height: '*',
            opacity: '.2',
            transform: 'translateX(20px)',
          })
        ),
        animate(
          '.35s ease',
          style({
            height: '*',
            opacity: 1,
            transform: 'translateX(0)',
          })
        ),
      ]),
    ]),
  ]),
  menuAnimation: trigger('enterAnimation', [
    transition(':enter', [
      style({ transform: 'translateX(150%)' }),
      animate('500ms', style({ transform: 'translateX(0)' })),
    ]),
    transition(':leave', [
      style({ transform: 'translateX(0)' }),
      animate('500ms', style({ transform: 'translateX(150%)' })),
    ]),
  ]),
};
