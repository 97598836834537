import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleAuthenticationGuard } from '@guards';

const routes: Routes = [
  {
    path: '',
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./pages/provisional-start/provisional-start.module').then(
        (m) => m.ProvisionalStartModule
      ),
  },
  {
    path: 'cliente',
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./pages/client/client.module').then((m) => m.ClientModule),
    data: {
      roles: ['CLIENT'],
    },
  },
  {
    path: 'broker',
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./pages/broker/broker.module').then((m) => m.BrokerModule),
    data: {
      roles: ['BROKER', 'BROKERLEADER'],
    },
    canActivate: [RoleAuthenticationGuard],
  },
  {
    path: 'admin',
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./pages/super-admin/super-admin.module').then(
        (m) => m.SuperAdminModule
      ),
    data: {
      roles: ['ADMIN', 'SUPERADMIN'],
    },
    canActivate: [RoleAuthenticationGuard],
  },
  {
    path: 'iniciar-sesion',
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./pages/session/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'recuperar-contrasena',
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./pages/session/recover-password/recover-password.module').then(
        (m) => m.RecoverPasswordModule
      ),
  },
  {
    path: 'nueva-contrasena',
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./pages/session/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: 'establecer/contrasena',
    runGuardsAndResolvers: 'always',
    loadChildren: () =>
      import('./pages/session/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: 'videollamada',
    loadChildren: () =>
      import('./pages/video-call/video-call.module').then(
        (m) => m.VideoCallModule
      ),
  },
  {
    path: 'video-visita',
    loadChildren: () =>
      import('./pages/end-of-visit-video/end-of-visit-video.module').then(
        (m) => m.EndOfVisitVideoModule
      ),
  },
  {
    path: 'registro-prospecto',
    loadChildren: () =>
      import('./pages/prospect-register/prospect-register.module').then(
        (m) => m.ProspectRegisterModule
      ),
  },
  {
    path: '**',
    redirectTo: 'iniciar-sesion',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
