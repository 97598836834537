import { environment } from '@environments/environment';

export const FirebaseConfig = {
  apiKey: environment.FIREBASE.APIKEY,
  authDomain: environment.FIREBASE.AUTHDOMAIN,
  databaseURL: environment.FIREBASE.DATABASEURL,
  projectId: environment.FIREBASE.PROJECTID,
  storageBucket: environment.FIREBASE.STORAGEBUCKET,
  messagingSenderId: environment.FIREBASE.MESSAGINGSENDERID,
  appId: environment.FIREBASE.APPID,
  measurementId: environment.FIREBASE.MEASUREMENTID,
};
