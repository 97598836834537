import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalAreYouSure } from '@models';
import { Subscription } from 'rxjs';
import { ModalAreYouSureService } from './modal-are-you-sure/modal-are-you-sure.service';

@Component({
  selector: 'ggi-journey-modal-are-you-sure-handler',
  templateUrl: './modal-are-you-sure-handler.component.html',
  styleUrls: ['./modal-are-you-sure-handler.component.scss'],
})
export class ModalAreYouSureHandlerComponent implements OnInit, OnDestroy {
  private createModalAreYouSure = Subscription.EMPTY;
  private destroyModalAreYouSure = Subscription.EMPTY;
  public isModalAreYouSureActive = false;
  public dataModalAreYouSure: ModalAreYouSure;

  constructor(private modalAreYouSureService: ModalAreYouSureService) {}

  ngOnInit(): void {
    this.createModalAreYouSure = this.modalAreYouSureService.new.subscribe(
      (data: ModalAreYouSure) => {
        if (data) {
          this.dataModalAreYouSure = data;
          this.isModalAreYouSureActive = true;
        }
      }
    );

    this.destroyModalAreYouSure = this.modalAreYouSureService.response.subscribe(
      () => {
        this.isModalAreYouSureActive = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.createModalAreYouSure.unsubscribe();
    this.destroyModalAreYouSure.unsubscribe();
  }
}
