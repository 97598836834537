const SERVER = `https://btest.smartwalk.com.mx/`;
const CLIENT = `${SERVER}client`;
const BROKER = `${SERVER}broker`;
const ADMIN = `${SERVER}admin`;
const SUPER_ADMIN = `${SERVER}superadmin`;
const CATALOG = `${SERVER}catalog`;
const BACKOFFICE = `${SERVER}backoffice`;
const VIRTUAL_VISIT = `${SERVER}virtual-visit`;
const VIDEO_CALL = `${SERVER}videocall`;

export const USER_ENVIRONMENT = `https://ftest.smartwalk.com.mx/`;
export const environment = {
  SERVER,
  PRODUCTION: false,
  PROJECT_VIEW: true,
  MIYANA_WEBSITE: 'https://miyanacomercial.com.mx',
  LOGIN: `${SERVER}login`,
  LOGIN_WITH_SOCIAL_MEDIA: `${SERVER}login/socialnetwork`,
  LOGOUT: `${SERVER}logout`,
  RECOVER_PASSWORD: `${SERVER}recovery`,
  RESET_PASSWORD: `${SERVER}reset`,
  CHANGE_PASSWORD: `${SERVER}password-renewal`,
  CLIENT_QUESTION: `${SERVER}visit/question`,
  SCHEDULE_VISIT: {
    HEADER: `${CLIENT}/calendar/main/appointment`,
    INTERESTS: `${CATALOG}/{typeProject}/interests`,
    SIGNUP: `${CLIENT}/signup`,
    GOOGLE: `${CLIENT}/google`,
    FACEBOOK: `${CLIENT}/facebook`,
    VISIT: `${CLIENT}/visit`,
    AVAILABLE_BROKERS: `${CLIENT}/calendar`,
    ANONYMUS: `${CLIENT}/anonymous/visit`,
    NV_SIGNUP: `${CLIENT}/nv/signup`,
    NV_GOOGLE: `${CLIENT}/nv/google`,
    NV_FACEBOOK: `${CLIENT}/nv/facebook`,
  },
  MATTERPORT: {
    APIKEY: 'nez9ueb26r8d8tfddas6qp0fb',
  },
  FIREBASE: {
    APIKEY: 'AIzaSyAZegRf5_07nOuxoz04hE5Y03mYl6fwPz4',
    AUTHDOMAIN: 'testsw-1e172.firebaseapp.com',
    DATABASEURL: 'https://testsw-1e172-default-rtdb.firebaseio.com',
    PROJECTID: 'testsw-1e172',
    STORAGEBUCKET: 'testsw-1e172.appspot.com',
    MESSAGINGSENDERID: '402209492714',
    APPID: '1:402209492714:web:54497c15f4e2fa382ad193',
    MEASUREMENTID: 'G-QMMJ3W88NW',
  },
  PROJECTS: {
    SUMMARY: `${SUPER_ADMIN}/project/summary`,
    PROJECTS: `${SUPER_ADMIN}/projects`,
    PROJECT: `${SUPER_ADMIN}/project`,
    BROKENDOWN: `${CATALOG}/brokendown-project`,
    PROJECT_DETAILS: `${SUPER_ADMIN}/project/`,
    EDIT: `${SUPER_ADMIN}/project/{projectId}`,
    ADD_COMPANY: `${SUPER_ADMIN}/project/{projectId}/typeProject/{typeProjectId}/company`,
    ADD_COMMON_SPACE: `${SUPER_ADMIN}/project/{projectId}/typeProject/{typeProjectId}/commonSpace`,
    TYPE_PROJECT_DETAILS: `${SUPER_ADMIN}/project/{projectId}/typeProject/{typeProjectId}`,
    PROPERTIES: `${SUPER_ADMIN}/project/{projectId}/typeProject/{typeProjectId}/properties`,
    PROPERTY: `${SUPER_ADMIN}/project/{projectId}/typeProject/{typeProjectId}/property`,
    PROJECT_TYPE: `${SUPER_ADMIN}/projectType/{projectTypeId}/features`,
    AVAILABLE_PROPERTIES: `${CATALOG}/project/{projectId}/typeProject/{typeProjectId}/available-properties`,
    VISIT_SUMMARY: `${SUPER_ADMIN}/project/{projectId}/typeProject/{typeProjectId}/visit-summary/{propertyId}`,
  },
  COMPANIES: {
    COMPANY: `${SUPER_ADMIN}/company`,
    AVAILABLE: `${CATALOG}/companies-unassigned`,
    DETAILS: `${SUPER_ADMIN}/company/{id}`,
    BROKER: `${SUPER_ADMIN}/company/{companyId}/broker/{brokerId}`,
    PROJECT_COMPANY: `${SUPER_ADMIN}/project/{projectId}/typeProject/{typeProjectId}/company`,
    ASSIGN_PROJECT: `${SUPER_ADMIN}/company/{companyId}/project/{projectId}/typeProject/{typeProjectId}`,
    TYPE_PROJECT_COMPANY: `${SUPER_ADMIN}/project/{projectId}/typeProject/{typeProjectId}/company/{companyId}`,
  },
  ADMIN: {
    ACCOUNT: `${SUPER_ADMIN}/account`,
    ADMINS: `${SUPER_ADMIN}/admins`,
    ADMIN: `${SUPER_ADMIN}/admin`,
    LAST: `${SUPER_ADMIN}/lastadmins`,
    QUESTION: `${ADMIN}/question`,
  },
  CLIENTE: {
    DASHBOARD: `${CLIENT}/dashboard`,
    VISIT_DETAILS: `${CLIENT}/upcoming/{visitId}`,
    HISTORY_DETAILS: `${CLIENT}/history/{visitId}`,
    VISIT_GUESS: `${CLIENT}/visit/{visitId}/guest`,
    DELETE_GUESS: `${CLIENT}/visit/{visitId}/guest/{guestId}`,
    DATA_CONTACT: `${CLIENT}/data-contact`,
  },
  VISIT: {
    QUALIFICATION: `${VIRTUAL_VISIT}/{visitId}/qualification`,
    LIKED: `${CATALOG}/liked`,
  },
  BROKER: {
    BROKER: `${CATALOG}/broker`,
    ACCOUNT: `${BROKER}/account`,
    UPDATE_PHOTO: `${BROKER}/account/photo`,
    VISIT_BROKER: `${BROKER}/visit/{visitId}/broker`,
    ROLE: `${BACKOFFICE}/broker/{brokerId}/role`,
    NEW_BROKER: `${BACKOFFICE}/broker`,
    BROKER_VISITING: `${BROKER}/dashboard`,
    BROKERS: `${BACKOFFICE}/brokers`,
    BROKERS_LEADERS: `${BACKOFFICE}/brokers/leaders`,
    BROKERS_LAST_REGISTERED: `${BACKOFFICE}/brokers/last-registered`,
    BROKERS_UPCOMING_VISITS: `${BACKOFFICE}/brokers/upcoming-visits`,
    BROKERS_DETAIL: `${BACKOFFICE}/broker/{brokerId}`,
    BROKERS_UPDATE_STATUS: `${BACKOFFICE}/broker/{brokerId}/status`,
    BROKERS_VISITS_HISTORY: `${BACKOFFICE}/broker/{brokerId}/visits`,
    BROKERS_CLIENTES: `${BACKOFFICE}/broker/{brokerId}/clients`,
    BROKER_VISIT: `${BROKER}/visit`,
    INTEREST: `${CATALOG}/{typeProject}/interests`,
    SCHEDULES: `${BROKER}/{brokerId}/schedules`,
    SLOTS: `${BROKER}/{brokerId}/slots`,
    UPDATE_SCHEDULES: `${BROKER}/{brokerId}/schedule/{scheduleId}`,
  },
  CLIENT_MANAGE: {
    CLIENTS: `${BACKOFFICE}/clients`,
    HIGH_PRIORITY: `${BACKOFFICE}/clients/high-priority`,
    LAST_TEN: `${BACKOFFICE}/clients/last-ten-consulted`,
    CLIENT: `${BACKOFFICE}/client`,
    UPCOMING_VISITS: `${BACKOFFICE}/client/{clientId}/visits`,
    HISTORY: `${BACKOFFICE}/client/{clientId}/visits-history`,
    GUESTS: `${BACKOFFICE}/client/{clientId}/guests`,
    VISIT_DETAIL: `${BACKOFFICE}/client/{clientId}/visit/{visitId}`,
    ADD_GUEST: `${BACKOFFICE}/client/{clientId}/broker/{brokerId}/visit/{visitId}/guests`,
    EDIT_GUEST: `${BACKOFFICE}/client/{clientId}/visit/{visitId}/guest/{guestId}`,
    VISIT_DATETIME: `${BACKOFFICE}/client/{clientId}/visit/{visitId}`,
    DELETE_GUEST: `${BACKOFFICE}/client/{clientId}/guest/{guestId}`,
    NEW_VISIT: `${BACKOFFICE}/client/{clientId}/visit`,
    NOTE: `${BACKOFFICE}/client/{clientId}/notes`,
    PREVIOUS_GUESTS: `${CATALOG}/{clientId}/{brokerId}/previous-guests`,
    DOCUMENTS: `${BACKOFFICE}/client/{clientId}/visit/{visitId}/documents`,
    SUMMARY_NOTES: `${BACKOFFICE}/visit/{visitId}/note/{noteId}`,
    CANCEL_VISIT: `${BACKOFFICE}/client/{clientId}/broker/{brokerId}/visit/{visitId}`,
    INTERESTS: `${CATALOG}/interests`,
    PROFILE: `${CLIENT}/profile`,
    INTERESTS_BY_TYPE: `${CATALOG}/interests`,
    FINALIZE_SALE: `${BACKOFFICE}/client/{clientId}/visit/{visitId}/sale`,
    BROKER_OF_CLIENT: `${BACKOFFICE}/client/{clientId}/visit/{visitId}/broker`,
  },
  SENTRY: {
    SENTRY_DSN:
      'https://6211cc2779ed407bb3ac6e15692bb6de@o546962.ingest.sentry.io/5668976',
    SENTRY_DEBUG: false,
  },
  VIRTUAL_VISIT: {
    VISIT: `${VIRTUAL_VISIT}/visit/{visitId}`,
    VALIDATE: `${VIRTUAL_VISIT}/{visitId}/validate`,
    MATTERPORT: `${VIRTUAL_VISIT}/{visitId}/matterport`,
    PROJECT_FEATURES: `${VIRTUAL_VISIT}/project/{projectId}/features`,
    PROPERTY_DETAIL: `${VIRTUAL_VISIT}/{visitId}/property/{propertyId}`,
    COMMENTS: `${VIRTUAL_VISIT}/{visitId}/author/{userId}/comment`,
  },
  VIDEO_CALL: {
    ROOM: `${VIDEO_CALL}/{visitId}/room`,
    ACCESS_TOKEN: `${VIDEO_CALL}/{visitId}/room/accessToken`,
  },
  GOOGLE_ANALYTICS: 'G-6BQMXNLL37',
};
