import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { getItemSession } from '@utilities';

@Injectable({
  providedIn: 'root',
})
export class RoleAuthenticationGuard implements CanActivate {
  constructor(private readonly router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const role = getItemSession('user', 'application');
    if (route.data.roles.includes(role)) {
      return true;
    }

    let routes = ['iniciar-sesion'];
    if (role) {
      switch (role) {
        case 'CLIENT':
          routes = ['cliente'];
          break;
        case 'BROKER':
        case 'BROKERLEADER':
          routes = ['broker'];
          break;
        case 'ADMIN':
        case 'SUPERADMIN':
          routes = ['admin', 'proyectos'];
          break;
      }
    }

    this.router.navigate(routes, {
      queryParams: { returnUrl: state.url },
    });

    return false;
  }
}
