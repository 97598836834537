<div class="modal" *ngIf="modal && message !== ''">
  <div class="modal-content">
    <div class="modal-title">
      <div class="modal-close">
        <button type="button" (click)="close()">&#10799;</button>
      </div>
    </div>
    <span class="icon-In-Progress"></span>
    <div class="body-1">{{ message }}</div>
    <button class="btn-filled" (click)="close()" *ngIf="button !== ''">
      {{ button }}
    </button>
  </div>
</div>
