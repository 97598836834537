import { Component, Input, OnChanges } from '@angular/core';
import { ModalAreYouSure } from '@models';
import { Subject } from 'rxjs';
import { ModalAreYouSureService } from './modal-are-you-sure.service';

@Component({
  selector: 'ggi-journey-modal-are-you-sure',
  templateUrl: './modal-are-you-sure.component.html',
  styleUrls: ['./modal-are-you-sure.component.scss'],
})
export class ModalAreYouSureComponent implements OnChanges {
  public title = '';
  public description = '';
  public txtAccepted = '';
  public txtCancel = '';

  @Input() data: ModalAreYouSure;
  public subject: Subject<boolean>;

  constructor(private service: ModalAreYouSureService) {}

  ngOnChanges(): void {
    if (this.data) {
      this.setData();
    }
  }

  private setData(): void {
    const { title, description, accepted, cancel } = this.data;

    this.title = title;
    this.description = description;
    this.txtAccepted = accepted;
    this.txtCancel = cancel;
  }

  public cancel(): void {
    this.service.close();
  }

  public accepted(): void {
    this.service.continue();
  }
}
