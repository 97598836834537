import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalSuccessService {
  public modal: Subject<boolean> = new Subject<boolean>();
  public message: Subject<string> = new Subject<string>();
  public button: Subject<string> = new Subject<string>();

  constructor() {}

  public close(): void {
    this.modal.next(false);
    this.button.next('');
    this.message.next('');
  }

  public open(message: any): void {
    this.message.next(message);
  }
}
