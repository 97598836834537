import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loader = false;

  public notShow: Subject<boolean> = new Subject<boolean>();

  loadingStatus: Subject<boolean> = new Subject<boolean>();

  public setNotShowStatus(status: boolean): void {
    this.notShow.next(status);
  }

  public get loading(): boolean {
    return this.loader;
  }

  public set loading(value) {
    this.loader = value;
    this.loadingStatus.next(value);
  }

  public startLoading(): void {
    this.loading = true;
  }

  public stopLoading(): void {
    this.loading = false;
  }
}
