import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalAreYouSureHandlerComponent } from './modal-are-you-sure-handler.component';
import { ModalAreYouSureComponent } from './modal-are-you-sure/modal-are-you-sure.component';

@NgModule({
  declarations: [ModalAreYouSureHandlerComponent, ModalAreYouSureComponent],
  imports: [CommonModule],
  exports: [ModalAreYouSureHandlerComponent],
})
export class ModalAreYouSureHandlerModule {}
