import { Component, OnInit } from '@angular/core';
import { ModalSuccessService } from './modal-success.service';

@Component({
  selector: 'ggi-journey-modal-success',
  templateUrl: './modal-success.component.html',
  styleUrls: ['./modal-success.component.scss'],
})
export class ModalSuccessComponent implements OnInit {
  public message = '';
  public modal = false;
  public button = '';

  constructor(private modalSuccess: ModalSuccessService) {}

  ngOnInit(): void {
    this.subscribeModal();
  }

  public close(): void {
    this.modalSuccess.close();
  }

  public subscribeModal(): void {
    this.modalSuccess.button.subscribe((res: string) => {
      this.button = res;
    });
    this.modalSuccess.message.subscribe((res: string) => {
      this.message = res;
    });
    this.modalSuccess.modal.subscribe((res: boolean) => {
      this.modal = res;
    });
  }
}
