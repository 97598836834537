<div class="modal">
  <div class="modal-content">
    <div class="modal-close">
      <button type="button" (click)="cancel()">&#10799;</button>
    </div>
    <div class="modal-body">
      <h4 *ngIf="title !== ''">
        {{ title }}
      </h4>
      <p
        class="body-2"
        *ngIf="description !== ''"
        [innerHTML]="description"
      ></p>

      <div class="modal-footer">
        <button
          type="button"
          class="btn-without-filled"
          (click)="cancel()"
          *ngIf="txtCancel !== ''"
        >
          {{ txtCancel }}
        </button>

        <button
          type="submit"
          class="btn-filled"
          (click)="accepted()"
          *ngIf="txtAccepted !== ''"
        >
          {{ txtAccepted }}
        </button>
      </div>
    </div>
  </div>
</div>
