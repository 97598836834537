import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Toast, ToastType } from '@models';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  public isNewToast: BehaviorSubject<Toast> = new BehaviorSubject(undefined);
  public currentNewToast = this.isNewToast.asObservable();

  constructor() {}

  public success(msg: string): void {
    const toast: Toast = {
      message: msg,
      type: ToastType.success,
    };
    this.isNewToast.next(toast);
  }

  public error(msg?: string): void {
    const toast: Toast = {
      message: msg || 'Para continuar revisa los campos marcados.',
      type: ToastType.error,
    };
    this.isNewToast.next(toast);
  }
}
