import { Injectable } from '@angular/core';
import { ModalAreYouSure } from '@models';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalAreYouSureService {
  public new: Subject<ModalAreYouSure> = new Subject<ModalAreYouSure>();
  public response: Subject<boolean> = new Subject<boolean>();

  constructor() {}

  public create(data: ModalAreYouSure): void {
    this.new.next(data);
  }

  public continue(): void {
    this.response.next(true);
  }

  public close(): void {
    this.response.next(false);
  }
}
