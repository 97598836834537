import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { timeInterval } from 'rxjs/operators';
import { Toast, ToastType } from '../../models/toast.model';
import { Animation } from './animation';
import { ToastService } from './toast.service';
import * as moment_ from 'moment';
const moment = moment_;

@Component({
  selector: 'ggi-journey-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [Animation.newToast],
})
export class ToastComponent implements OnInit, OnDestroy {
  @Input() timeOut = 5;
  public toasts: Array<Toast> = [];
  private subscription: Subscription = Subscription.EMPTY;

  private counter: Subscription;

  public readonly TypeAlert = ToastType;

  constructor(private toastService: ToastService) {}

  ngOnInit(): void {
    this.listener();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.destroyCounter();
  }

  /**
   *
   * @param index Remove element of array
   */
  public remove(index: number): void {
    this.toasts.splice(index, 1);
  }

  /**
   * Counter for delete and destroy subscription
   */
  private counterTime(): void {
    const counter = interval(1000);
    this.counter = counter.pipe(timeInterval()).subscribe(() => {
      if (this.toasts.length > 0) {
        const diff = moment.duration(moment().diff(moment(this.toasts[0].date)));
        if (diff.asSeconds() >= Number(this.timeOut)) {
          this.remove(0);
        }
      } else if (this.toasts.length <= 0) {
        this.destroyCounter();
      }
    });
  }

  private destroyCounter(): void {
    if (this.counter) {
      this.counter.unsubscribe();
      this.counter = undefined;
    }
  }

  /**
   * If exits a new toast, show and create counter
   */
  private listener(): void {
    this.subscription = this.toastService.currentNewToast.subscribe((toast: Toast) => {
      if (toast) {
        if (!this.counter) {
          this.counterTime();
        }
        toast.date = moment().valueOf();
        this.toasts.push(toast);
      }
    });
  }
}
