import { Component } from '@angular/core';

@Component({
  selector: 'ggi-journey-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public onActivate(): void {
    window.scroll(0, 0);
  }
}
