export class Toast {
  type?: ToastType;
  message: string;
  date?: number;
}

export enum ToastType {
  success = 'success',
  error = 'error',
}
